import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/school/login",
  },
  {
    path: "/cameraTest",
    name: "cameraTest",
    component: () => import("@/views/Test/CameraTest.vue"),
  },
  //data
  {
    path: "/data",
    name: "data",
    component: () => import("@/views/Data/Data.vue")
  },
  //school 部分
  {
    path: "/school",
    component: () => import("@/views/School/School.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/views/School/Login/Login.vue"),
      },
      {
        path: "/",
        name: "home",
        component: () => import("@/views/School/Home/Home.vue"),
        meta: { requireAuth: true },
        children: [
          {
            path: "index",
            name: "index",
            component: () => import("@/views/School/Index/Index.vue"),
            meta: { requireAuth: true },
          },
          {
            path: "interview",
            component: () => import("@/views/School/Interview/Interview.vue"),
            meta: { requireAuth: true },
            children: [
              {
                path: "/",
                name: "import",
                component: () =>
                  import("@/views/School/Interview/InterviewImport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "list",
                name: "list",
                component: () =>
                  import("@/views/School/Interview/InterviewList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "ai",
                name: "ai",
                component: () =>
                  import("@/views/School/Interview/InterviewAi.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "buy",
                name: "buy",
                component: () =>
                  import("@/views/School/Interview/InterviewBuy.vue"),
                meta: { requireAuth: true },
              },{
                path: "detail",
                name: "detail",
                component: () =>
                  import("@/views/School/Interview/InterviewDetail.vue"),
                meta: { requireAuth: true },
              },{
                path: "aiDetail",
                name: "aiDetail",
                component: () =>
                  import("@/views/School/Interview/InterviewAiDetail.vue"),
                meta: { requireAuth: true },
              },
            ],
          },
          {
            path: "book",
            component: () => import("@/views/School/Book/Book.vue"),
            meta: { requireAuth: true },
            children: [
              {
                path: "/",
                name: "new",
                component: () => import("@/views/School/Book/BookNew.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "import",
                name: "import",
                component: () => import("@/views/School/Book/BookImport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookPrint",
                name: "bookPrint",
                component: () =>
                  import("@/views/School/Book/BookBookPrint.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "codePrint",
                name: "codePrint",
                component: () =>
                  import("@/views/School/Book/BookCodePrint.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "cateList",
                name: "cateList",
                component: () => import("@/views/School/Book/BookCateList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "cate",
                name: "cate",
                component: () => import("@/views/School/Book/BookCate.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "setting",
                name: "setting",
                component: () => import("@/views/School/Book/BookSetting.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "picture",
                name: "pictrue",
                component: () => import("@/views/School/Book/BookPicture.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "dataImport",
                name: "dataImport",
                component: () =>
                  import("@/views/School/Book/BookDataImport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "dataList",
                name: "dataList",
                component: () => import("@/views/School/Book/BookDataList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "batchList",
                name: "batchList",
                component: () =>
                  import("@/views/School/Book/BookBatchList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "siteList",
                name: "siteList",
                component: () => import("@/views/School/Book/BookSiteList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "inventory",
                name: "inventory",
                component: () =>
                  import("@/views/School/Book/BookInventory.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "destory",
                name: "destory",
                component: () => import("@/views/School/Book/BookDestory.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "old",
                name: "old",
                component: () => import("@/views/School/Book/BookOld.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "codeDel",
                name: "codeDel",
                component: () => import("@/views/School/Book/BookCodeDel.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "codeReplace",
                name: "codeReplace",
                component: () =>
                  import("@/views/School/Book/BookCodeReplace.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "codeLose",
                name: "codeLose",
                component: () => import("@/views/School/Book/BookCodeLose.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "unused",
                name: "unused",
                component: () => import("@/views/School/Book/BookUnused.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "marc",
                name: "marc",
                component: () => import("@/views/School/Book/BookMarc.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "excelMarc",
                name: "excelMarc",
                component: () =>
                  import("@/views/School/Book/BookExcelMarc.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "list",
                name: "list",
                component: () => import("@/views/School/Book/BookList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "destoryList",
                name: "destoryList",
                component: () =>
                  import("@/views/School/Book/BookDestoryList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "oldList",
                name: "oldList",
                component: () => import("@/views/School/Book/BookOldList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "loseList",
                name: "loseList",
                component: () => import("@/views/School/Book/BookLoseList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "defaceList",
                name: "defaceList",
                component: () =>
                  import("@/views/School/Book/BookDefaceList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportCopy",
                name: "reportCopy",
                component: () =>
                  import("@/views/School/Book/BookReportCopy.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportCate",
                name: "reportCate",
                component: () =>
                  import("@/views/School/Book/BookReportCate.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportSite",
                name: "reportSite",
                component: () =>
                  import("@/views/School/Book/BookReportSite.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportJob",
                name: "reportJob",
                component: () =>
                  import("@/views/School/Book/BookReportJob.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "report",
                name: "report",
                component: () => import("@/views/School/Book/BookReport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "rfidNew",
                name: "rfidNew",
                component: () => import("@/views/School/Book/BookRfidNew.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "rfidBind",
                name: "rfidBind",
                component: () => import("@/views/School/Book/BookRfidBind.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "rfidList",
                name: "rfidList",
                component: () => import("@/views/School/Book/BookRfidList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "inventoryList",
                name: "inventoryList",
                component: () =>
                  import("@/views/School/Book/BookInventoryList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookBat",
                name: "bookBat",
                component: () => import("@/views/School/Book/BookBookBat.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "book",
                name: "book",
                component: () => import("@/views/School/Book/BookBook.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookEdit",
                name: "bookEdit",
                component: () => import("@/views/School/Book/BookBookEdit.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookDetail",
                name: "bookDetail",
                component: () => import("@/views/School/Book/BookBookDetail.vue"),
                meta: { requireAuth: true },
              },
            ],
          },
          {
            path: "journal",
            component: () => import("@/views/School/Journal/Journal.vue"),
            meta: { requireAuth: true },
            children: [
              {
                path: "advanceRecive",
                name: "advanceRecive",
                component: () =>
                  import("@/views/School/Journal/JournalAdvanceRecive.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "/",
                name: "advance",
                component: () =>
                  import("@/views/School/Journal/JournalAdvance.vue"),
                meta: { requireAuth: true },
              },{
                path: "supplier",
                name: "supplier",
                component: () =>
                  import("@/views/School/Journal/JournalSupplier.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "signin",
                name: "signin",
                component: () =>
                  import("@/views/School/Journal/JournalSignin.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "signinDetail",
                name: "signinDetail",
                component: () =>
                  import("@/views/School/Journal/JournalSigninDetail.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "stapleDetail",
                name: "stapleDetail",
                component: () =>
                  import("@/views/School/Journal/JournalStapleDetail.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "staple",
                name: "staple",
                component: () =>
                  import("@/views/School/Journal/JournalStaple.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "codePrint",
                name: "codePrint",
                component: () =>
                  import("@/views/School/Journal/JournalCodePrint.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "old",
                name: "old",
                component: () =>
                  import("@/views/School/Journal/JournalOld.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "destoryList",
                name: "destoryList",
                component: () =>
                  import("@/views/School/Journal/JournalDestoryList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "batchList",
                name: "batchList",
                component: () =>
                  import("@/views/School/Journal/JournalBatchList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "setting",
                name: "setting",
                component: () =>
                  import("@/views/School/Journal/JournalSetting.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "publisherFre",
                name: "publisherFre",
                component: () =>
                  import("@/views/School/Journal/JournalPublisherFre.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "advanceList",
                name: "advanceList",
                component: () =>
                  import("@/views/School/Journal/JournalAdvanceList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "signinList",
                name: "signinList",
                component: () =>
                  import("@/views/School/Journal/JournalSigninList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "stapleList",
                name: "stapleList",
                component: () =>
                  import("@/views/School/Journal/JournalStapleList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "list",
                name: "list",
                component: () =>
                  import("@/views/School/Journal/JournalList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "distributeList",
                name: "distributeList",
                component: () =>
                  import("@/views/School/Journal/JournalDistributeList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportAdvance",
                name: "reportAdvance",
                component: () =>
                  import("@/views/School/Journal/JournalReportAdvance.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportSignin",
                name: "reportSignin",
                component: () =>
                  import("@/views/School/Journal/JournalReportSignin.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportStaple",
                name: "reportStaple",
                component: () =>
                  import("@/views/School/Journal/JournalReportStaple.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "report",
                name: "report",
                component: () =>
                  import("@/views/School/Journal/JournalReport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportCate",
                name: "reportCate",
                component: () =>
                  import("@/views/School/Journal/JournalReportCate.vue"),
                meta: { requireAuth: true },
              },
            ],
          },
          {
            path: "circulate",
            component: () => import("@/views/School/Circulate/Circulate.vue"),
            meta: { requireAuth: true },
            children: [
              {
                path: "/",
                name: "book",
                component: () =>
                  import("@/views/School/Circulate/CirculateBook.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "read",
                name: "read",
                component: () =>
                  import("@/views/School/Circulate/CirculateRead.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "advance",
                name: "advance",
                component: () =>
                  import("@/views/School/Circulate/CirculateAdvance.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "borrowAdvance",
                name: "borrowAdvance",
                component: () =>
                  import("@/views/School/Circulate/CirculateBorrowAdvance.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "overdue",
                name: "overdue",
                component: () =>
                  import("@/views/School/Circulate/CirculateOverdue.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "import",
                name: "import",
                component: () =>
                  import("@/views/School/Circulate/CirculateImport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "new",
                name: "new",
                component: () =>
                  import("@/views/School/Circulate/CirculateNew.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "pay",
                name: "pay",
                component: () =>
                  import("@/views/School/Circulate/CirculatePay.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "payBook",
                name: "payBook",
                component: () =>
                  import("@/views/School/Circulate/CirculatePayBook.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "payList",
                name: "payList",
                component: () =>
                  import("@/views/School/Circulate/CirculatePayList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportPay",
                name: "reportPay",
                component: () =>
                  import("@/views/School/Circulate/CirculateReportPay.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "nobackList",
                name: "nobackList",
                component: () =>
                  import("@/views/School/Circulate/CirculateNobackList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "historyList",
                name: "historyList",
                component: () =>
                  import("@/views/School/Circulate/CirculateHistoryList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "overdueList",
                name: "overdueList",
                component: () =>
                  import("@/views/School/Circulate/CirculateOverdueList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "holiday",
                name: "holiday",
                component: () =>
                  import("@/views/School/Circulate/CirculateHoliday.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "advanceList",
                name: "advanceList",
                component: () =>
                  import("@/views/School/Circulate/CirculateAdvanceList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportBook",
                name: "reportBook",
                component: () =>
                  import("@/views/School/Circulate/CirculateReportBook.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportReader",
                name: "reportReader",
                component: () =>
                  import("@/views/School/Circulate/CirculateReportReader.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportRate",
                name: "reportRate",
                component: () =>
                  import("@/views/School/Circulate/CirculateReportRate.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "noborrowList",
                name: "noborrowList",
                component: () =>
                  import("@/views/School/Circulate/CirculateNoborrowList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "borrowAdvanceList",
                name: "borrowAdvanceList",
                component: () =>
                  import(
                    "@/views/School/Circulate/CirculateBorrowAdvanceList.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "advanceType",
                name: "advanceType",
                component: () =>
                  import("@/views/School/Circulate/CirculateAdvanceType.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "readList",
                name: "readList",
                component: () =>
                  import("@/views/School/Circulate/CirculateReadList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportRead",
                name: "reportRead",
                component: () =>
                  import("@/views/School/Circulate/CirculateReportRead.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "list",
                name: "list",
                component: () =>
                  import("@/views/School/Circulate/CirculateList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportCate",
                name: "reportCate",
                component: () =>
                  import("@/views/School/Circulate/CirculateReportCate.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reportBorrowTotal",
                name: "reportBorrowTotal",
                component: () =>
                  import(
                    "@/views/School/Circulate/CirculateReportBorrowTotal.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "reportReaderTotal",
                name: "reportReaderTotal",
                component: () =>
                  import(
                    "@/views/School/Circulate/CirculateReportReaderTotal.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "reportOrgTotal",
                name: "reportOrgTotal",
                component: () =>
                  import(
                    "@/views/School/Circulate/CirculateReportOrgTotal.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "advanceReport",
                name: "advanceReport",
                component: () =>
                  import("@/views/School/Circulate/CirculateAdvanceReport.vue"),
                meta: { requireAuth: true },
              },
            ],
          },
          {
            path: "reader",
            component: () => import("@/views/School/Reader/Reader.vue"),
            meta: { requireAuth: true },
            children: [
              {
                path: "/",
                name: "setting",
                component: () =>
                  import("@/views/School/Reader/ReaderSetting.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "import",
                name: "import",
                component: () =>
                  import("@/views/School/Reader/ReaderImport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "org",
                name: "org",
                component: () => import("@/views/School/Reader/ReaderOrg.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "orgDestoryList",
                name: "orgDestoryList",
                component: () =>
                  import("@/views/School/Reader/ReaderOrgDestoryList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "type",
                name: "type",
                component: () => import("@/views/School/Reader/ReaderType.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "reader",
                name: "reader",
                component: () =>
                  import("@/views/School/Reader/ReaderReader.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "readerBat",
                name: "readerBat",
                component: () =>
                  import("@/views/School/Reader/ReaderReaderBat.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "head",
                name: "head",
                component: () =>
                  import("@/views/School/Reader/ReaderHead.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "photoBind",
                name: "photoBind",
                component: () =>
                  import("@/views/School/Reader/ReaderPhotoBind.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "deposit",
                name: "deposit",
                component: () =>
                  import("@/views/School/Reader/ReaderDeposit.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "list",
                name: "list",
                component: () => import("@/views/School/Reader/ReaderList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "report",
                name: "report",
                component: () =>
                  import("@/views/School/Reader/ReaderReport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "destoryList",
                name: "destoryList",
                component: () =>
                  import("@/views/School/Reader/ReaderDestoryList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "face",
                name: "face",
                component: () => import("@/views/School/Reader/ReaderFace.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "finger",
                name: "finger",
                component: () =>
                  import("@/views/School/Reader/ReaderFinger.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "idCard",
                name: "idCard",
                component: () =>
                  import("@/views/School/Reader/ReaderIdCard.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "icCard",
                name: "icCard",
                component: () =>
                  import("@/views/School/Reader/ReaderIcCard.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "registerList",
                name: "registerList",
                component: () =>
                  import("@/views/School/Reader/ReaderRegisterList.vue"),
                meta: { requireAuth: true },
              },
            ],
          },
          {
            path: "setting",
            component: () => import("@/views/School/Setting/Setting.vue"),
            meta: { requireAuth: true },
            children: [
              {
                path: "/",
                name: "info",
                component: () =>
                  import("@/views/School/Setting/SettingInfo.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "personal",
                name: "personal",
                component: () =>
                  import("@/views/School/Setting/SettingPersonal.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "role",
                name: "role",
                component: () =>
                  import("@/views/School/Setting/SettingRole.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "admin",
                name: "admin",
                component: () =>
                  import("@/views/School/Setting/SettingAdmin.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "data",
                name: "data",
                component: () =>
                  import("@/views/School/Setting/SettingData.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "index",
                name: "index",
                component: () =>
                  import("@/views/School/Setting/SettingIndex.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "email",
                name: "email",
                component: () =>
                  import("@/views/School/Setting/SettingEmail.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "sms",
                name: "sms",
                component: () =>
                  import("@/views/School/Setting/SettingSms.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "mobile",
                name: "mobile",
                component: () =>
                  import("@/views/School/Setting/SettingMobile.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "interface",
                name: "interface",
                component: () =>
                  import("@/views/School/Setting/SettingInterface.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "library",
                name: "library",
                component: () =>
                  import("@/views/School/Setting/SettingLibrary.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "rate",
                name: "rate",
                component: () =>
                  import("@/views/School/Setting/SettingRate.vue"),
                meta: { requireAuth: true },
              },
            ],
          },
          {
            path: "report",
            component: () => import("@/views/School/Report/Report.vue"),
            meta: { requireAuth: true },
            children: [
              {
                path: "/",
                name: "bookList",
                component: () =>
                  import("@/views/School/Report/ReportBookList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookDestoryList",
                name: "bookDestoryList",
                component: () =>
                  import("@/views/School/Report/ReportBookDestoryList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookOldList",
                name: "bookOldList",
                component: () =>
                  import("@/views/School/Report/ReportBookOldList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookLoseList",
                name: "bookLoseList",
                component: () =>
                  import("@/views/School/Report/ReportBookLoseList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookReportCopy",
                name: "bookReportCopy",
                component: () =>
                  import("@/views/School/Report/ReportBookReportCopy.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookReportCate",
                name: "bookReportCate",
                component: () =>
                  import("@/views/School/Report/ReportBookReportCate.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookReportSite",
                name: "bookReportSite",
                component: () =>
                  import("@/views/School/Report/ReportBookReportSite.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookReportJob",
                name: "bookReportJob",
                component: () =>
                  import("@/views/School/Report/ReportBookReportJob.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "bookReport",
                name: "bookReport",
                component: () =>
                  import("@/views/School/Report/ReportBookReport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "journalAdvanceList",
                name: "journalAdvanceList",
                component: () =>
                  import("@/views/School/Report/ReportJournalAdvanceList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "journalReportCate",
                name: "journalReportCate",
                component: () =>
                  import("@/views/School/Report/ReportJournalReportCate.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "journalSigninList",
                name: "journalSigninList",
                component: () =>
                  import("@/views/School/Report/ReportJournalSigninList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "journalStapleList",
                name: "journalStapleList",
                component: () =>
                  import("@/views/School/Report/ReportJournalStapleList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "journalList",
                name: "journalList",
                component: () =>
                  import("@/views/School/Report/ReportJournalList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "journalDistributeList",
                name: "journalDistributeList",
                component: () =>
                  import(
                    "@/views/School/Report/ReportJournalDistributeList.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "journalReportAdvance",
                name: "journalReportAdvance",
                component: () =>
                  import(
                    "@/views/School/Report/ReportJournalReportAdvance.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "journalReportSignin",
                name: "journalReportSignin",
                component: () =>
                  import("@/views/School/Report/ReportJournalReportSignin.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "journalReportStaple",
                name: "journalReportStaple",
                component: () =>
                  import("@/views/School/Report/ReportJournalReportStaple.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "journalReport",
                name: "journalReport",
                component: () =>
                  import("@/views/School/Report/ReportJournalReport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "readerList",
                name: "readerList",
                component: () =>
                  import("@/views/School/Report/ReportReaderList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "readerReport",
                name: "readerReport",
                component: () =>
                  import("@/views/School/Report/ReportReaderReport.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "circulateAdvanceList",
                name: "circulateAdvanceList",
                component: () =>
                  import(
                    "@/views/School/Report/ReportCirculateAdvanceList.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "circulateReportBook",
                name: "circulateReportBook",
                component: () =>
                  import("@/views/School/Report/ReportCirculateReportBook.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "circulateReportReader",
                name: "circulateReportReader",
                component: () =>
                  import(
                    "@/views/School/Report/ReportCirculateReportReader.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "circulateReportRate",
                name: "circulateReportRate",
                component: () =>
                  import("@/views/School/Report/ReportCirculateReportRate.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "circulateAdvanceType",
                name: "circulateAdvanceType",
                component: () =>
                  import(
                    "@/views/School/Report/ReportCirculateAdvanceType.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "circulateReadList",
                name: "circulateReadList",
                component: () =>
                  import("@/views/School/Report/ReportCirculateReadList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "circulateReportRead",
                name: "circulateReportRead",
                component: () =>
                  import("@/views/School/Report/ReportCirculateReportRead.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "circulateList",
                name: "circulateList",
                component: () =>
                  import("@/views/School/Report/ReportCirculateList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "circulateReportCate",
                name: "circulateReportCate",
                component: () =>
                  import("@/views/School/Report/ReportCirculateReportCate.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "circulateReportBorrowTotal",
                name: "circulateReportBorrowTotal",
                component: () =>
                  import(
                    "@/views/School/Report/ReportCirculateReportBorrowTotal.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "circulateReportReaderTotal",
                name: "circulateReportReaderTotal",
                component: () =>
                  import(
                    "@/views/School/Report/ReportCirculateReportReaderTotal.vue"
                  ),
                meta: { requireAuth: true },
              },
              {
                path: "circulateReportOrgTotal",
                name: "circulateReportOrgTotal",
                component: () =>
                  import(
                    "@/views/School/Report/ReportCirculateReportOrgTotal.vue"
                  ),
                meta: { requireAuth: true },
              },
            ],
          },
          {
            path: "opac",
            component: () => import("@/views/School/Opac/Opac.vue"),
            meta: { requireAuth: true },
            children: [
              {
                path: "picture",
                name: "picture",
                component: () => import("@/views/School/Opac/OpacPicture.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "/",
                name: "suggestNew",
                component: () =>
                  import("@/views/School/Opac/OpacSuggestNew.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "suggestReader",
                name: "suggestReader",
                component: () =>
                  import("@/views/School/Opac/OpacSuggestReader.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "notice",
                name: "notice",
                component: () => import("@/views/School/Opac/OpacNotice.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "rule",
                name: "rule",
                component: () => import("@/views/School/Opac/OpacRule.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "readerSuggest",
                name: "readerSuggest",
                component: () =>
                  import("@/views/School/Opac/OpacReaderSuggest.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "readerComment",
                name: "readerComment",
                component: () =>
                  import("@/views/School/Opac/OpacReaderComment.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "wechatRegister",
                name: "wechatRegister",
                component: () =>
                  import("@/views/School/Opac/OpacWechatRegister.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "readerMessage",
                name: "readerMessage",
                component: () =>
                  import("@/views/School/Opac/OpacReaderMessage.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "link",
                name: "link",
                component: () => import("@/views/School/Opac/OpacLink.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "setting",
                name: "setting",
                component: () => import("@/views/School/Opac/OpacSetting.vue"),
                meta: { requireAuth: true },
              },
              ,
              {
                path: "noticeEdit",
                name: "noticeEdit",
                component: () =>
                  import("@/views/School/Opac/OpacNoticeEdit.vue"),
                meta: { requireAuth: true },
              },
            ],
          },
          {
            path: "library",
            component: () => import("@/views/School/Library/Library.vue"),
            meta: { requireAuth: true },
            children: [
              {
                path: "/",
                name: "new",
                component: () =>
                  import("@/views/School/Library/LibraryNew.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "back",
                name: "back",
                component: () =>
                  import("@/views/School/Library/LibraryBack.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "list",
                name: "list",
                component: () =>
                  import("@/views/School/Library/LibraryList.vue"),
                meta: { requireAuth: true },
              },
              {
                path: "report",
                name: "report",
                component: () =>
                  import("@/views/School/Library/LibraryReport.vue"),
                meta: { requireAuth: true },
              },
            ],
          },
          {
            path: "help",
            component: () => import("@/views/School/Help/Help.vue"),
            meta: { requireAuth: true },
          },
          {
            path: "hardware",
            component: () => import("@/views/School/Hardware/Hardware.vue"),
            meta: { requireAuth: true },
          },
        ],
      },
    ],
  },
  //workstation 部分
  {
    path: "/workstation",
    component: () => import("@/views/Workstation/Workstation.vue"),
    children: [
      {
        path: "login",
        name: "workstationLogin",
        meta: { requireAuth: false },
        component: () => import("@/views/Workstation/Login/Login.vue"),
      },
      {
        path: "/",
        name: "workstattionHome",
        meta: { requireAuth: true },
        component: () => import("@/views/Workstation/Home/Home.vue"),
        children: [
          {
            path: "book",
            name: "book",
            meta: { requireAuth: true },
            component: () => import("@/views/Workstation/Book/Book.vue"),
            children:[
              {
                path: "/",
                name: "rfid",
                meta: { requireAuth: true },
                component: () => import("@/views/Workstation/Book/BookRfid.vue"),
              },
              {
                path: "rfidList",
                name: "rfidList",
                meta: { requireAuth: true },
                component: () => import("@/views/Workstation/Book/BookRfidList.vue"),
              },
            ]
          },{
            path: "reader",
            name: "reader",
            meta: { requireAuth: true },
            component: () => import("@/views/Workstation/Reader/Reader.vue"),
            children:[
              {
                path: "/",
                name: "reader",
                meta: { requireAuth: true },
                component: () => import("@/views/Workstation/Reader/ReaderReader.vue"),
              },{
                path: "faceList",
                name: "faceList",
                meta: { requireAuth: true },
                component: () => import("@/views/Workstation/Reader/FaceList.vue"),
              },{
                path: "face",
                name: "face",
                meta: { requireAuth: true },
                component: () => import("@/views/Workstation/Reader/ReaderFace.vue"),
              },{
                path: "ic",
                name: "ic",
                meta: { requireAuth: true },
                component: () => import("@/views/Workstation/Reader/ReaderIc.vue"),
              },{
                path: "finger",
                name: "finger",
                meta: { requireAuth: true },
                component: () => import("@/views/Workstation/Reader/ReaderFinger.vue"),
              },
            ]
          },{
            path: "circulate",
            name: "circulate",
            meta: { requireAuth: true },
            component: () => import("@/views/Workstation/Circulate/Circulate.vue"),
            children:[
              {
                path: "/",
                name: "borrow",
                meta: { requireAuth: true },
                component: () => import("@/views/Workstation/Circulate/CirculateBorrow.vue"),
              },{
                path: "back",
                name: "back",
                meta: { requireAuth: true },
                component: () => import("@/views/Workstation/Circulate/CirculateBack.vue"),
              },{
                path: "renew",
                name: "renew",
                meta: { requireAuth: true },
                component: () => import("@/views/Workstation/Circulate/CirculateRenew.vue"),
              },
            ]
          },{
            path: "floor",
            name: "floor",
            meta: { requireAuth: true },
            component: () => import("@/views/Workstation/Floor/Floor.vue"),
          },
        ],
      },
    ]
  },
  //inventory部分
  {
    path: "/inventory",
    component: () => import("@/views/Inventory/Inventory.vue"),
    children:[
      {
        path: "/",
        name: "home",
        meta: { requireAuth: false },
        component: () => import("@/views/Inventory/Home/Home.vue"),
      },
      {
        path: "floor",
        name: "floor",
        meta: { requireAuth: false },
        component: () => import("@/views/Inventory/Floor/Floor.vue"),
        children:[
          {
            path: "select",
            name: "select",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Floor/FloorSelect.vue"),
          },
          {
            path: "new",
            name: "new",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Floor/FloorNew.vue"),
          },
          {
            path: "book",
            name: "book",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Floor/FloorBook.vue"),
          },
          {
            path: "list",
            name: "list",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Floor/FloorList.vue"),
          },
          {
            path: "report",
            name: "report",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Floor/FloorReport.vue"),
          },
          {
            path: "reportDetail",
            name: "reportDetail",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Floor/FloorReportDetail.vue"),
          },
        ]
      },
      {
        path: "book",
        name: "book",
        meta: { requireAuth: false },
        component: () => import("@/views/Inventory/Book/Book.vue"),
        children:[
          {
            path: "search",
            name: "search",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Book/BookSearch.vue"),
          },
          {
            path: "back",
            name: "back",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Book/BookBack.vue"),
          },
          {
            path: "bat",
            name: "bat",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Book/BookBat.vue"),
          }
        ]
      },
      {
        path: "inventory",
        name: "inventory",
        meta: { requireAuth: false },
        component: () => import("@/views/Inventory/Inventory/Inventory.vue"),
        children:[
          {
            path: "select",
            name: "select",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Inventory/InventorySelect.vue"),
          },
          {
            path: "batchSelect",
            name: "batchSelect",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Inventory/InventoryBatchSelect.vue"),
          },
          {
            path: "batch",
            name: "batch",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Inventory/InventoryBatch.vue"),
          },
          {
            path: "inventory",
            name: "inventory",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Inventory/InventoryInventory.vue"),
          },
          {
            path: "report",
            name: "report",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Inventory/InventoryReport.vue"),
          },
          {
            path: "reportDetail",
            name: "reportDetail",
            meta: { requireAuth: false },
            component: () => import("@/views/Inventory/Inventory/InventoryReportDetail.vue"),
          }
        ]
      }
    ]
  },
  //self 部分
  {
    path: "/self",
    component: () => import("@/views/Self/Self.vue"),
    children: [
      {
        path: "/",
        name: "home",
        meta: { requireAuth: false },
        component: () => import("@/views/Self/Home/Home.vue"),
      },{
        path: "login",
        name: "login",
        meta: { requireAuth: false },
        component: () => import("@/views/Self/Login/Login.vue"),
      },{
        path: "loginFace",
        name: "loginFace",
        meta: { requireAuth: false },
        component: () => import("@/views/Self/Login/LoginFace.vue"),
      },{
        path: "loginScan",
        name: "loginScan",
        meta: { requireAuth: false },
        component: () => import("@/views/Self/Login/LoginScan.vue"),
      },{
        path: "borrow",
        name: "borrow",
        meta: { requireAuth: false },
        component: () => import("@/views/Self/Book/Borrow.vue"),
      },{
        path: "back",
        name: "back",
        meta: { requireAuth: false },
        component: () => import("@/views/Self/Book/Back.vue"),
      },{
        path: "renew",
        name: "renew",
        meta: { requireAuth: false },
        component: () => import("@/views/Self/Book/Renew.vue"),
      },{
        path: "list",
        name: "list",
        meta: { requireAuth: false },
        component: () => import("@/views/Self/Book/List.vue"),
      },{
        path: "history",
        name: "history",
        meta: { requireAuth: false },
        component: () => import("@/views/Self/Book/History.vue"),
      },{
        path: "search",
        name: "search",
        meta: { requireAuth: false },
        component: () => import("@/views/Self/Book/Search.vue"),
      },
    ]
  },
  //selfH 部分
  {
    path: "/selfH",
    component: () => import("@/views/SelfH/Self.vue"),
    children: [
      {
        path: "/",
        name: "home",
        meta: { requireAuth: false },
        component: () => import("@/views/SelfH/Home/Home.vue"),
      },{
        path: "login",
        name: "login",
        meta: { requireAuth: false },
        component: () => import("@/views/SelfH/Login/Login.vue"),
      },{
        path: "loginFace",
        name: "loginFace",
        meta: { requireAuth: false },
        component: () => import("@/views/SelfH/Login/LoginFace.vue"),
      },{
        path: "loginScan",
        name: "loginScan",
        meta: { requireAuth: false },
        component: () => import("@/views/SelfH/Login/LoginScan.vue"),
      },{
        path: "borrow",
        name: "borrow",
        meta: { requireAuth: false },
        component: () => import("@/views/SelfH/Book/Borrow.vue"),
      },{
        path: "back",
        name: "back",
        meta: { requireAuth: false },
        component: () => import("@/views/SelfH/Book/Back.vue"),
      },{
        path: "renew",
        name: "renew",
        meta: { requireAuth: false },
        component: () => import("@/views/SelfH/Book/Renew.vue"),
      },{
        path: "list",
        name: "list",
        meta: { requireAuth: false },
        component: () => import("@/views/SelfH/Book/List.vue"),
      },{
        path: "history",
        name: "history",
        meta: { requireAuth: false },
        component: () => import("@/views/SelfH/Book/History.vue"),
      },{
        path: "search",
        name: "search",
        meta: { requireAuth: false },
        component: () => import("@/views/SelfH/Book/Search.vue"),
      },
    ]
  },
  //opac部分
  {
    path: "/opac",
    component: () => import("@/views/Opac/Opac.vue"),
    children: [
      {
        path: "/",
        name: "opacIndex",
        meta: { requireAuth: false },
        component: () => import("@/views/Opac/Index/Index.vue"),
      },
      {
        path: "login",
        name: "opacLogin",
        meta: { requireAuth: false },
        component: () => import("@/views/Opac/Login/Login.vue"),
      },
      {
        path: "home",
        name: "opacHome",
        meta: { requireAuth: false },
        component: () => import("@/views/Opac/Home/Home.vue"),
        children: [
          {
            path: "/",
            name: "opacRule",
            meta: { requireAuth: false },
            component: () => import("@/views/Opac/Rule/RuleList.vue"),
          },
          {
            path: "ruleDetail",
            name: "opacRuleDetail",
            meta: { requireAuth: false },
            component: () => import("@/views/Opac/Rule/RuleDetail.vue"),
          },
          {
            path: "notice",
            name: "opacNotice",
            meta: { requireAuth: false },
            component: () => import("@/views/Opac/Notice/NoticeList.vue"),
          },{
            path: "noticeDetail",
            name: "opacNoticeDetail",
            meta: { requireAuth: false },
            component: () => import("@/views/Opac/Notice/NoticeDetail.vue"),
          },
          {
            path: "bookReader",
            name: "opacBookReader",
            meta: { requireAuth: false },
            component: () => import("@/views/Opac/BookReader/BookReaderList.vue"),
          },
          {
            path: "bookNew",
            name: "opacBookNew",
            meta: { requireAuth: false },
            component: () => import("@/views/Opac/BookNew/BookNewList.vue"),
          },
          {
            path: "bookHot",
            name: "opacBookHot",
            meta: { requireAuth: false },
            component: () => import("@/views/Opac/BookHot/BookHot.vue"),
          },
          {
            path: "bookCate",
            name: "opacBookCate",
            meta: { requireAuth: false },
            component: () => import("@/views/Opac/BookCate/BookCate.vue"),
          },
          {
            path: "bookSearch",
            name: "opacBookSearch",
            meta: { requireAuth: false },
            component: () => import("@/views/Opac/BookSearch/BookSearch.vue"),
          },{
            path: "bookDetail",
            name: "opacBookDetail",
            meta: { requireAuth: false },
            component: () => import("@/views/Opac/Book/BookDetail.vue"),
          },{
            path: "person",
            name: "opacPerson",
            meta: { requireAuth: true },
            component: () => import("@/views/Opac/Person/Person.vue"),
            children:[
              {
                path: "/",
                name: "opacPersonInfo",
                meta: { requireAuth: true },
                component: () => import("@/views/Opac/Person/PersonInfo.vue"),
              },{
                path: "borrowList",
                name: "opacPersonBorrowList",
                meta: { requireAuth: true },
                component: () => import("@/views/Opac/Person/PersonBorrowList.vue"),
              },{
                path: "historyList",
                name: "opacPersonHistoryList",
                meta: { requireAuth: true },
                component: () => import("@/views/Opac/Person/PersonHistoryList.vue"),
              },{
                path: "advanceList",
                name: "opacPersonAdvanceList",
                meta: { requireAuth: true },
                component: () => import("@/views/Opac/Person/PersonAdvanceList.vue"),
              },{
                path: "suggestList",
                name: "opacPersonSuggestList",
                meta: { requireAuth: true },
                component: () => import("@/views/Opac/Person/PersonSuggestList.vue"),
              },{
                path: "depositList",
                name: "opacPersonDepositList",
                meta: { requireAuth: true },
                component: () => import("@/views/Opac/Person/PersonDepositList.vue"),
              },{
                path: "payBookList",
                name: "opacPersonPayBookList",
                meta: { requireAuth: true },
                component: () => import("@/views/Opac/Person/PersonPayBookList.vue"),
              },{
                path: "password",
                name: "opacPersonPassword",
                meta: { requireAuth: true },
                component: () => import("@/views/Opac/Person/PersonPassword.vue"),
              },
            ]
          },

        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
